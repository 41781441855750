import Link from "next/link";
import Image from "next/image";
import { challengesList, cloudHiring } from "@/constants";
import { useSelector } from "react-redux";

const HiringChallenges = () => {

    const { data } = useSelector(state => state.contentful),
        content = data?.['461TOY4kW7JWn34MfftAQc'],
        challengeList = data?.['challengeList'],
        secondaryList = data?.['secondaryList']

    return (
        <div>
            <div className="bg-[url('/home/ellipse-challenges.svg')] bg-no-repeat bg-cover">
                <div className="grid grid-cols-3 pt-20 sm:pt-32">
                    <div className="hidden lg:block">
                        <video
                            autoPlay
                            loop
                            muted
                            className="opacity-40 max-w-7xl relative top-28"
                        >
                            <source
                                src="/home/earth-gif.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>

                    <div className="col-span-3 lg:col-span-2">
                        <section
                            id="secondary-features"
                            aria-label="Features for simplifying everyday business tasks"
                        >
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="mx-auto text-center lg:text-left">
                                    <h2
                                        className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
                                        data-test-id="global-challenges-title"
                                    >
                                        {content?.globalChallengesTitle || `Global Challenges in Hiring`}
                                    </h2>
                                    <p
                                        className="mt-4 text-lg tracking-tight text-gray-500"
                                        data-test-id="global-challenges-description"
                                    >
                                        {content?.globalChallengesDescription || `The lack of streamlined processes and
                                        bandwidth continues to hurt the growth
                                        of business, even with clear hiring
                                        objectives.`}
                                    </p>
                                </div>
                                <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
                                    {(challengeList)?.map((value, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="bg-secondary-50 p-5"
                                                data-test-id={`challenges-${index}`}
                                            >
                                                <div className="mx-auto max-w-2xl">
                                                    <div className="w-10 rounded-lg">
                                                        <Image
                                                            src={value?.icon?.fields?.file?.url ? 'https:' + value?.icon?.fields?.file?.url : "/loading-placeholder.png"}
                                                            alt={value?.icon?.fields?.title}
                                                            // src={value.icon}
                                                            //  alt={value.icon}
                                                            width={40}
                                                            height={40}
                                                            loading="lazy"
                                                        />
                                                    </div>
                                                    <h3
                                                        className="mt-6 text-lg font-medium text-primary-700"
                                                        data-test-id={`challenges-${index}-title`}
                                                    >
                                                        {value.title}
                                                    </h3>

                                                    <p
                                                        className="mt-4 text-lg text-neutral-900"
                                                        data-test-id={`challenges-${index}-description`}
                                                    >
                                                        {value.desc}
                                                    </p>
                                                    <div
                                                        className="flex mt-2"
                                                        data-test-id={`challenges-${index}-source`}
                                                    >
                                                        <span className="text-sm text-gray-500">
                                                            Source:
                                                        </span>
                                                        <Link href={value?.link || "#"}>
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                data-test-id={`challenges-${index}-source-link`}
                                                            >
                                                                <Image
                                                                    src={value?.sourceImage?.fields?.file?.url ?'https:' + value?.sourceImage?.fields?.file?.url : "/loading-placeholder.png"}
                                                                    alt={value?.sourceImage?.fields?.title}
                                                                    // src={
                                                                    //     value.source
                                                                    // }
                                                                    // alt={
                                                                    //     value.source
                                                                    // }
                                                                    width={20}
                                                                    height={20}
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {(challengeList)?.map((value, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="bg-secondary-50 p-5"
                                                data-test-id={`challenges-${index}`}
                                            >
                                                <div className="mx-auto max-w-2xl">
                                                    <div className="w-10 rounded-lg">
                                                        <Image
                                                            src={value?.icon?.fields?.file?.url ?'https:' + value?.icon?.fields?.file?.url : "/loading-placeholder.png"}
                                                            alt={value?.icon?.fields?.title}
                                                            // src={value.icon}
                                                            //  alt={value.icon}
                                                            width={40}
                                                            height={40}
                                                            loading="lazy"
                                                        />
                                                    </div>
                                                    <h3
                                                        className="mt-6 text-lg font-medium text-primary-700"
                                                        data-test-id={`challenges-${index}-title`}
                                                    >
                                                        {value.title}
                                                    </h3>

                                                    <p
                                                        className="mt-4 text-lg text-neutral-900"
                                                        data-test-id={`challenges-${index}-description`}
                                                    >
                                                        {value.desc}
                                                    </p>
                                                    <div
                                                        className="flex mt-2"
                                                        data-test-id={`challenges-${index}-source`}
                                                    >
                                                        <span className="text-sm text-gray-500">
                                                            Source:
                                                        </span>
                                                        <Link href={value?.link || "#"}>
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                data-test-id={`challenges-${index}-source-link`}
                                                            >
                                                                <Image
                                                                    src={value?.sourceImage?.fields?.file?.url ? 'https:' + value?.sourceImage?.fields?.file?.url : "/loading-placeholder.png"}
                                                                    alt={value?.sourceImage?.fields?.title}
                                                                    // src={
                                                                    //     value.source
                                                                    // }
                                                                    // alt={
                                                                    //     value.source
                                                                    // }
                                                                    width={20}
                                                                    height={20}
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="hidden lg:mt-20 lg:block">
                                    <div
                                        className="grid grid-cols-2 gap-4"
                                        role="tablist"
                                        aria-orientation="horizontal"
                                    >
                                        {(challengeList)?.map((value, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="bg-secondary-50 p-5 row-span-7"
                                                    data-test-id={`challenges-${index}`}
                                                >
                                                    <div className="relative">
                                                        <div className="w-11">
                                                            <Image
                                                                src={value?.icon?.fields?.file?.url ? 'https:' + value?.icon?.fields?.file?.url : "/loading-placeholder.png"}
                                                                alt={value?.icon?.fields?.title}
                                                                width={45}
                                                                height={45}
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <h3
                                                            className="mt-6 text-lg font-bold text-primary-700"
                                                            data-test-id={`challenges-${index}-title`}
                                                        >
                                                            {value.title}
                                                        </h3>

                                                        <p
                                                            className="mt-4 text-lg text-neutral-900"
                                                            data-test-id={`challenges-${index}-description`}
                                                        >
                                                            {value.desc}
                                                        </p>
                                                        <div
                                                            className="flex mt-2"
                                                            data-test-id={`challenges-${index}-source`}
                                                        >
                                                            <span className="text-sm text-gray-500">
                                                                Source:
                                                            </span>
                                                            <Link
                                                                href={
                                                                    value?.link || "#"
                                                                }
                                                            >
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    data-test-id={`challenges-${index}-source-link`}
                                                                >
                                                                    <Image
                                                                        src={value?.sourceImage?.fields?.file?.url ?'https:' + value?.sourceImage?.fields?.file?.url : "/loading-placeholder.png"}
                                                                        alt={value?.sourceImage?.fields?.title}
                                                                        width={
                                                                            115
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                        loading="lazy"
                                                                    />
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <section
                    id="secondary-features"
                    aria-label="Features for simplifying everyday business tasks"
                    className="mt-10 pt-20 sm:pt-32 pb-10"
                >
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl md:text-center">
                            <h2
                                className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
                                data-test-id="say-hello-title"
                            >
                                {content?.secondaryFeatureTitle || `Say Hello to Cloud Hiring`}
                            </h2>
                            <p
                                className="mt-4 text-xl tracking-tight text-gray-500"
                                data-test-id="say-hello-description"
                            >
                                {content?.secondaryFeatureDescription || `Our cloud hiring platform enables you to do
                                exactly what it sounds like; growing engineering
                                teams with just a few clicks.`}
                            </p>
                        </div>
                        <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
                            {(secondaryList)?.map((value, index) => {
                                return (
                                    <div
                                        className="mx-auto max-w-2xl"
                                        key={index}
                                        data-test-id={`cloud-${index}`}
                                    >
                                        <div className="w-10 rounded-full bg-secondary-50 p-1.5">
                                            <Image
                                                src={value?.icon?.fields?.file?.url ? 'https:' + value?.icon?.fields?.file?.url : "/loading-placeholder.png"}
                                                alt={value?.icon?.fields?.title}
                                                width={40}
                                                height={40}
                                                loading="lazy"
                                            />
                                        </div>
                                        <h3
                                            className="mt-6 text-lg font-medium text-primary-700"
                                            data-test-id={`cloud-${index}-title`}
                                        >
                                            {value.title}
                                        </h3>
                                        <p
                                            className="mt-4 text-lg text-neutral-900"
                                            data-test-id={`cloud-${index}-description`}
                                        >
                                            {value.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="hidden lg:mt-20 lg:block">
                            <div
                                className="grid grid-cols-3 gap-8"
                                role="tablist"
                                aria-orientation="horizontal"
                            >
                                {(secondaryList)?.map((value, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="transform transition duration-500 hover:scale-110 hover:shadow-lg p-5"
                                            data-test-id={`cloud-${index}`}
                                        >
                                            <div className="relative">
                                                <div className="w-10 rounded-full bg-secondary-50 p-1.5">
                                                    <Image
                                                        src={value?.icon?.fields?.file?.url ? 'https:' + value?.icon?.fields?.file?.url : "/loading-placeholder.png"}
                                                        alt={value?.icon?.fields?.title}
                                                        width={40}
                                                        height={40}
                                                        loading="lazy"
                                                    />
                                                </div>
                                                <h3
                                                    className="mt-6 text-lg font-bold text-primary-700"
                                                    data-test-id={`cloud-${index}-title`}
                                                >
                                                    <span className="absolute inset-0" />
                                                    {value.title}
                                                </h3>

                                                <p
                                                    className="mt-4 text-lg text-neutral-900"
                                                    data-test-id={`cloud-${index}-description`}
                                                >
                                                    {value.description}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
                                <div className="mx-auto max-w-lg text-center">
                                    <Link href="/demo">
                                        <a
                                            className="group inline-flex items-center justify-center rounded-full py-4 px-6 mb-10 text-xl font-semibold bg-primary-700 mt-10 text-white hover:bg-primary-600"
                                            data-test-id="get-started-today"
                                            target="_blank"
                                        >
                                            Book a demo today
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default HiringChallenges;
