import React from "react";
import Image from "next/image";

const CImage = (props) => {
    return (
        <div
            className={"next-image ".concat(props.className || "")}
            style={props.style || {}}
        >
            <div className="relative w-full h-full">
                <Image
                    {...props}
                    className={"absolute top-0 left-0 w-full h-full ".concat(
                        props.imgclassname || ""
                    )}
                />
            </div>
        </div>
    );
};

export default CImage;
