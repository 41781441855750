import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Image from "next/image";
import CImage from "@/components/layouts/CImage";
import { useSelector } from "react-redux";

export const FAQAccordion = ({ items }) => {
    const [isOpen, setIsOpen] = useState(null);

    const toggle = (index) => {
        if (isOpen === index) {
            setIsOpen(null);
        } else {
            setIsOpen(index);
        }
    };
    return (
        <div className="w-full space-y-1">
            {items.map((item, index) => {
                return (
                    <div key={index} className="w-full bg-white">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
                            <div className="w-full">
                                <div
                                    className="flex justify-between items-center py-4 px-8 cursor-pointer shadow-sm"
                                    onClick={() => toggle(index)}
                                >
                                    <span className="flex space-x-4 font-display text-xl tracking-tight text-gray-500">
                                        <span>&#x2022;</span>
                                        <h3>{item.title}</h3>
                                    </span>

                                    <Image
                                        className={`h-3 ${index !== isOpen && "-rotate-90"
                                            }`}
                                        src="/home/chevron-down.svg"
                                        alt="chevron-down"
                                        loading="lazy"
                                        width={14}
                                        height={14}
                                    />
                                </div>

                                {isOpen === index && (
                                    <div className="px-8 py-4 flex space-x-6">
                                        <span className="opacity-0">
                                            &#x2022;
                                        </span>
                                        <p
                                            className="text-gray-400  whitespace-pre-line"
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const QualitiesTriangle = ({ qualities, time }) => {
    const [currentQuality, setCurrentQuality] = useState(qualities[0]);
    const timeOutRef = useRef(null);

    useEffect(() => {
        timeOutRef.current = setTimeout(() => {
            const index = qualities.findIndex(
                (quality) => quality.title === currentQuality.title
            );
            if (index === qualities.length - 1) {
                setCurrentQuality(qualities[0]);
            } else {
                setCurrentQuality(qualities[index + 1]);
            }
        }, time || 3000);
        return () => {
            clearTimeout(timeOutRef.current);
        };
    }, [currentQuality]);

    return (
        <section className="bg-ellipse px-10  sm:pb-16 pt-16 sm:px-0 max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-[3fr_4fr_3fr] lg:grid-cols-[1fr_500px_1fr] justify-center">
            <div className="hidden sm:flex flex-col space-y-2">
                <div className="grow" />
                <div>
                    <h3 className="text-2xl font-semibold text-center pb-4">
                        {qualities[0]?.title}
                    </h3>
                    <p className="text-center">{qualities[0]?.description}</p>
                </div>
            </div>
            <div>
                <div className="hidden sm:flex text-center space-y-2 justify-center flex-col min-h-[150px] max-h-[150px]">
                    <h3 className="text-2xl font-semibold pb-4">
                        {qualities[1]?.title}
                    </h3>
                    <p>{qualities[1]?.description}</p>
                </div>
                <div className="static sm:hidden text-center space-y-2 min-h-[180px] max-h-[180px]">
                    <h3 className="text-2xl font-semibold pb-4">
                        {currentQuality?.title}
                    </h3>
                    <p>{currentQuality?.description}</p>
                </div>

                <div className="aspect-square w-full relative">
                    <Image
                        src="/home/qualities-triangle.svg"
                        className={`opacity-100 sm:opacity-0 absolute top-4 left-0 right-0 bottom-0 z-10 rotating-triangle`}
                        style={{
                            animationDuration: `${time * 3 || 9000}ms`,
                        }}
                    />
                    <Image
                        src={"/home/qualities-triangle.svg"}
                        className="hidden sm:block w-full scale-110 translate-y-[10%]"
                    />
                </div>
            </div>
            <div className="hidden sm:flex flex-col">
                <div className="grow" />
                <div className="text-end">
                    <h3 className="text-2xl font-semibold text-center pb-4">
                        {qualities[2]?.title}
                    </h3>
                    <p className="text-center">{qualities[2]?.description}</p>
                </div>
            </div>
        </section>
    );
};

export const InfoSectionFeatures = ({ infoSections }) => (
    <section className="mt-16">
        {infoSections.map((section, index) => (
            <div
                key={index}
                className={`flex flex-col md:flex-row ${index % 2 === 1 && "md:flex-row-reverse"
                    }`}
            >
                <div className="w-full md:w-1/2 min-h-[500px] flex items-center justify-center bg-[#CFF5FE] bg-opacity-[0.15]">
                    <div className="w-3/4">
                        <h2
                            className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl whitespace-pre-line	"
                            data-test-id={`info-title-${index}`}
                        >
                            {section.title}
                        </h2>
                        <p
                            className="mt-6 text-xl text-gray-500 mx-auto max-w-3xl "
                            data-test-id={`info-description-${index}`}
                        >
                            {section.description}
                        </p>
                    </div>
                </div>
                {section.hero && (
                    <div className="w-full md:w-1/2  self-stretch min-h-[300px] flex items-center justify-center relative">
                        <section.hero />
                    </div>
                )}
                {section.heroImg && (
                    <div className="w-full md:w-1/2  self-stretch min-h-[300px] flex items-center justify-center relative">
                        <Image
                            src={section?.heroImg}
                            srcSet={
                                section.heroImg2 &&
                                section.heroImg2.concat(" 2x")
                            }
                            alt="hero image"
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                )}
            </div>
        ))}
    </section>
);

export const ReviewSection = ({ heading, description }) => {
    const { data } = useSelector(state => state.contentful),
        content = data?.['candidateReviews']

    const ReviewCard = ({ name, rating, desc, img, index }) => (
        <div
            className={`flex flex-col items-center pt-20 ${index === 1 || index === 3
                ? "scale-105 -translate-y-[5%]"
                : "scale-95"
                }`}
        >
            <div className="relative mx-10 rounded-t-xl bg-white">
                <CImage
                    src={"/logos/Star-9.svg"}
                    height={"185"}
                    width={"195"}
                    className="ml-auto mr-0 w-1/3 h-1/3 translate-x-[33%] -mt-[50px]"
                    alt={"start"}
                    loading="lazy"
                />
                <div className="px-8 mb-16 relative">
                    <CImage
                        height="29"
                        width="22"
                        src={"/logos/quote.svg"}
                        alt={"quote"}
                        className="mb-2 absolute top-0 -translate-y-[150%] -translate-x-1/2"
                        loading="lazy"
                    />
                    <h3 className="text-lg h-[80px]">{desc}</h3>
                </div>
            </div>
            <div className="w-full">
                <div className="mx-4 flex space-x-4 py-4 px-8 justify-between items-center bg-[#FAFAFA] rounded-b-xl">
                    <CImage
                        src={img}
                        alt={name}
                        className="w-16 h-16"
                        imgclassname={"rounded-full"}
                        width={64}
                        height={64}
                    />
                    <div className="text-center">
                        <div className="flex items-center space-x-2">
                            {[...Array(Math.floor(rating))].map((_, index) => (
                                <Image
                                    key={index}
                                    src="/logos/star-sm.svg"
                                    alt="star"
                                    width={20}
                                    height={20}
                                />
                            ))}
                            {rating % 1 !== 0 && (
                                <Image
                                    height={25}
                                    width={25}
                                    src="/logos/star-sm.svg"
                                    alt="star"
                                    style={{
                                        clipPath:
                                            "polygon(0 0, 50% 0, 50% 100%, 0% 100%)",
                                    }}
                                    loading="lazy"
                                />
                            )}
                        </div>
                        <h4 className="font-bold text-lg mt-2">{name}</h4>
                    </div>
                    <div />
                </div>
            </div>
        </div>
    );
    const reviews = [
        {
            name: "Sumit S",
            rating: 5,
            desc: "The interview was challenging and interesting. It's highly engaging.",
            img: "/logos/profile.svg",
        },
        {
            name: "Priyanka C",
            rating: 5,
            desc: "This was the first time that the interviewer made things so clear and explained everything. Thanks Tacnique.",
            img: "/logos/profile.svg",
        },
        {
            name: "Ankita R",
            rating: 4.5,
            desc: "It was a great interview session. The process was easy and smooth.",
            img: "/logos/profile.svg",
        },
        {
            name: "Arnab D",
            rating: 5,
            desc: "Really liked it and learnt a lot. Highly recommended.",
            img: "/logos/profile.svg",
        },
        {
            name: "Seema S",
            rating: 4.5,
            desc: "Good experience to have such wonderful discussion and getting instant feedbacks.",
            img: "/logos/profile.svg",
        },
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="pt-24" id="responsive-section">
            <div className="flex flex-col items-center">
                <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl font-bold">
                    {heading || "Testimonials From Engineers Speak Volumes"}
                </h2>
                <p className="mt-6 text-xl text-center mx-auto max-w-3xl tracking-tight text-gray-500">
                    {description ||
                        "Tacnique is the clear choice for job seekers who want to land their dream job. Join the happy Tacnique family today!"}
                </p>
            </div>
            <div
                className="py-16"
                style={{
                    //linear gradient white to 50% primary color
                    background:
                        "linear-gradient(180deg, transparent 0%, transparent 50%, #0E7783 50%,#0E7783 100%)",
                }}
            >
                <Slider {...settings}>
                    {reviews.map((review, index) => (
                        <ReviewCard key={index} {...review} index={index} />
                    ))}
                    {content?.map((review, index) => (
                        <ReviewCard key={index} {...{
                            ...review,
                            rating: parseInt(review.ratings || 0),
                            desc: review.description,
                            img: review?.userImage?.fields?.file?.url ?'https:' + review?.userImage?.fields?.file?.url : "/loading-placeholder.png",
                        }} index={index} />
                    ))}
                   
                </Slider>
            </div>
        </section>
    );
};

export const FAQSectionText = ({ description, isEngineer }) => {
    const router = useRouter();
    return (
        <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
                <div className="mx-auto max-w-lg text-center">
                    <Link
                        href={`${isEngineer || router?.pathname.includes("/about")
                            ? "/signup"
                            : "/demo"
                            }`}
                    >
                        <a
                            className={`${isEngineer ? "bg-primary-600" : "bg-red-400"
                                } group inline-flex items-center justify-center rounded-full py-3 px-8 text-lg mb-10 font-semibold mt-10 text-white hover:bg-primary-700`}
                            data-test-id="get-started-today"
                            target="_blank"
                        >
                            {isEngineer ? "Interview Now" : "Book a demo today"}
                        </a>
                    </Link>
                </div>
            </div>
            <div className="space-y-4 text-center mb-12">
                <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl font-bold">
                    Frequently Asked Questions
                </h2>
                <p>
                    {description ||
                        "Get quick and reliable answers to your questions on our cloud interview platform, candidate assessment, recruitment best practices, and more."}
                </p>
            </div>
        </>
    );
};

export const IntegrationsOffered = ({ heading, description }) => (
    <section
        id="secondary-features"
        aria-label="Features for simplifying everyday business tasks"
        className="pt-20 integration-container"
    >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center">
            <div className="mx-auto  md:text-center">
                <h2
                    className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl font-bold"
                    data-test-id="interview-title"
                >
                    {heading || "Integrations we offer"}
                </h2>
                <p
                    className="mt-6 text-xl mx-auto max-w-3xl tracking-tight text-gray-500"
                    data-test-id="interview-description"
                >
                    {description ||
                        "Worried about your ATS integrations? With Tacnique's custom ATS integrations, there's no need to change the way you operate."}
                </p>
            </div>
            <div className="mt-10 mx-auto  overflow-x-auto">
                <div className="min-w-[700px]">
                    <Image
                        src="/logos/integration/integrations.png"
                        alt="integrations"
                        layout="responsive"
                        width={1200}
                        height={500}
                    />
                </div>
            </div>
        </div>
    </section>
);
