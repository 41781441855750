import React, { useState } from "react";
import Image from "next/image";
import ModalVideo from "react-modal-video";
import Link from "next/link";

const HeaderNavEng = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="relative bg-white">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row mt-20 md:mt-15 items-center">
                <div className="relative z-10 pb-14 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                    <main className="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-24 lg:mt-20 lg:px-8 xl:mt-24">
                        <div className="text-left">
                            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                <span className="block xl:inline">
                                    One{" "}
                                    <span className="text-primary-600">
                                        Master Interview.
                                    </span>
                                </span>
                                <br className="hidden md:block" />
                                <span className="block xl:inline">
                                    Endless{" "}
                                    <span className="text-primary-600">
                                        Job Offers.
                                    </span>
                                </span>
                            </h1>
                            <p className="text-left mt-3 text-xl text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-2xl lg:mx-0">
                                Upgrade your job hunt with our cloud-based
                                hiring platform powered by AI for tailored
                                recommendations for you.
                            </p>
                            <div className="mt-5 sm:mt-8 space-y-9 md:space-y-0 sm:flex sm:justify-center lg:justify-start">
                                <div>
                                    <Link href="/signup">
                                        <a
                                            target="_self"
                                            className="w-full flex items-center justify-center px-8 py-3 text-xl font-medium rounded-full text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10"
                                            data-test-id="get-started"
                                        >
                                            Interview Now
                                        </a>
                                    </Link>
                                </div>
                                <div className="mt-2 md:mt-0 md:ml-5">
                                    <ModalVideo
                                        channel="youtube"
                                        autoplay
                                        isOpen={isOpen}
                                        videoId="QTJ2Jjzr7u4"
                                        onClose={() => setOpen(false)}
                                    />
                                    <button
                                        type="button"
                                        className="w-full cursor-pointer flex items-center justify-center rounded-full border px-8 py-3 md:py-3.5 text-xl font-medium border-primary-300 text-primary-600 hover:border-primary-400 active:bg-primary-100 active:text-primary-700"
                                        onClick={() => setOpen(true)}
                                        data-test-id="watch-video"
                                    >
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            aria-hidden="true"
                                            className="h-6 w-6 flex-none"
                                        >
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="11.5"
                                                stroke="#5CB0BA"
                                            />
                                            <path
                                                d="M9.5 14.382V9.618a.5.5 0 0 1 .724-.447l4.764 2.382a.5.5 0 0 1 0 .894l-4.764 2.382a.5.5 0 0 1-.724-.447Z"
                                                fill="#5CB0BA"
                                                stroke="#5CB0BA"
                                            />
                                        </svg>
                                        <span className="ml-2.5">
                                            Watch the video
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div className="w-full md:w-1/2">
                    <div className="w-full px-4 sm:px-6  md:w-full  mx-auto md:mr-auto">
                        <Image
                            src={"/home/hero-main-img-new.png"}
                            alt={"hero image"}
                            layout="responsive"
                            width={500}
                            height={300}
                            priority={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderNavEng;
