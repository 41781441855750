import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { useSelector } from "react-redux";

const BrandingLogos = ({ heading, footing, headingSize, isEngineer }) => {
    const router = useRouter();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
        ],
    };

    const { data } = useSelector(state => state.contentful)
    const content = data?.['5H6p09BqsABlm4LeKM8CDF']

    const logoWidth = 300;
    const logoHeight = 105;

    return (
        <div className="my-7">
            <div
                className={`mb-4 font-semibold text-md text-[#7D7D7D] text-center items-center ${headingSize || ""
                    }`}
            >
                {heading || "Trusted by"}
            </div>
            <div className="brand-logo-carousel">
                <Slider {...settings}>
                    {content?.companies?.map(
                        data => {
                            return <div>
                                <Image
                                    width={logoWidth}
                                    height={logoHeight}
                                    layout="responsive"
                                    className="mx-auto"
                                    src={data?.fields?.file?.url ? 'https:' + data?.fields?.file?.url : "/loading-placeholder.png"}
                                    alt="vts-logo"
                                    loading="lazy"
                                />
                            </div>
                        }
                    )}
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/vts-logo.svg"
                            alt="vts-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/zillow-logo.svg"
                            alt="zillow-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/medikabazaar-logo.svg"
                            alt="medikabazaar-logo"
                            loading="lazy"
                        />
                    </div>

                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/alhabtoor-logo.svg"
                            alt="alhabtoor-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/medicines_patent_pool-logo.svg"
                            alt="medicines_patent_pool-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/klub-logo.svg"
                            alt="klub-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/instarem.-logo.svg"
                            alt="instarem.-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/highnote-logo.svg"
                            alt="highnote-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/buildly-logo.svg"
                            alt="buildly-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/jll-logo.svg"
                            alt="jll-logo"
                            loading="lazy"
                        />
                    </div>
                    <div>
                        <Image
                            width={logoWidth}
                            height={logoHeight}
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/visa-logo.svg"
                            alt="visa-logo"
                            loading="lazy"
                        />
                    </div>
                    <div className="mt-3">
                        <Image
                            width="250"
                            height="55"
                            layout="responsive"
                            className="mx-auto"
                            src="/logos/cpx-logo.svg"
                            alt="cpx-logo"
                            loading="lazy"
                        />
                    </div>
                </Slider>
            </div>
            {footing && (
                <div className="mt-4 text-center text-md text-[#7D7D7D]">
                    {footing}
                </div>
            )}
            {!["/", "/companies", "/businesses"].includes(router?.pathname) && (
                <div className="mx-auto max-w-7xl px-4 pt-6 -pb-8 sm:px-6 lg:px-8 relative">
                    <div className="mx-auto max-w-lg text-center">
                        <Link
                            href={`${isEngineer ||
                                router?.pathname.includes("/about")
                                ? "/signup"
                                : "/demo"
                                }`}
                        >
                            <a
                                className={`${isEngineer ||
                                    router?.pathname.includes("/about")
                                    ? "bg-primary-600"
                                    : "bg-red-400"
                                    } group inline-flex items-center justify-center rounded-full py-3 px-8 text-lg mb-10 font-semibold mt-10 text-white hover:bg-primary-700`}
                                data-test-id="get-started-today"
                                target="_blank"
                            >
                                {isEngineer ||
                                    router?.pathname.includes("/about")
                                    ? "Interview Now"
                                    : "Book a demo"}
                            </a>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BrandingLogos;
