import Spinner from "./Spinner";
const SpinnerLoader = () => (
    <div className="flex items-center relative h-screen justify-center">
        <div className="w-[40px] h-[40px]">
            <Spinner forScreen={true} />
        </div>
    </div>
);

export default SpinnerLoader;
